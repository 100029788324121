export function isElementInViewport(el: HTMLElement): boolean {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight)
  );
}

export function deepCopy<T>(item: T): T {
  return JSON.parse(JSON.stringify(item));
}

export function generateId(): string {
  const head = new Date().getMilliseconds();
  const tail = Math.floor(Math.random() * 100 + 1);
  return `${head}${tail}`;
}

/**
 * produce RFC4122-compliant UUID
 * reference link: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
 */
export function generateUuid(): string {
  return (<any>[1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export function isEmpty(mixedVar: any, emptyValues = [undefined, null, '']) {
  let i, len;
  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i]) {
      return true;
    }
  }
  if (typeof mixedVar === 'object') {
    for (const item of Object.values(mixedVar)) {
      if (!isEmpty(item, emptyValues)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function getPluralValue(value: string, numberToSolve: number): string {
  if (numberToSolve === 1) {
    return value;
  }
  return value + 's';
}
