import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { ThirdPartyLoaderService } from './third-party-loader.service';

@Injectable({
  providedIn: 'root',
})
export class CookieyesService {
  constructor(
    private thirdPartyLoaderService: ThirdPartyLoaderService,
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
  ) {}

  run(): void {
    if (this.isHiration()) {
      return;
    }

    if (this.environment.cookieyesId) {
      this.thirdPartyLoaderService.loadExternalScript(
        `https://cdn-cookieyes.com/client_data/${this.environment.cookieyesId}/script.js`,
        'cookieyes',
      );
    }
  }

  private isHiration(): boolean {
    return window.location.pathname.includes('/app/resume-builder');
  }
}
