import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  ApiResponseModel,
  BundleDashboardListingModel,
  BundleModel,
  CourseDashboardListingModel,
  CourseDashboardModel,
  ProgramDashboardModel,
  RecentCoursesWrapperModel,
} from '@lms/shared/models';
import { CatalogService } from './catalog.service';
import { CurrentUserStorage } from '@lms/shared/storages';
import { StudentCourseService } from './student-course.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CatalogFacade {
  courses: CourseDashboardModel[] = [];
  coursesCompleted: CourseDashboardModel[] = [];
  programsCurrent: ProgramDashboardModel[] = [];
  programsExisted: ProgramDashboardModel[] = [];
  programsCompleted: ProgramDashboardModel[] = [];
  recentCourses$: BehaviorSubject<RecentCoursesWrapperModel[]> = new BehaviorSubject<RecentCoursesWrapperModel[]>([]);

  courses$ = this.catalogService.getCourses().pipe(
    map(res => {
      this.courses = res.data.courses;
      return res.data.courses;
    }),
  );
  coursesCompleted$ = this.catalogService.getCourses(true).pipe(
    map((response: ApiResponseModel<CourseDashboardListingModel>) => {
      this.coursesCompleted = response.data.courses;
      return response.data.courses;
    }),
  );
  programsCurrent$ = this.catalogService.getPrograms().pipe(
    map((response: ApiResponseModel<BundleDashboardListingModel>) => {
      this.programsCurrent = response.data.bundles;
      if (response.data.recentCourses) {
        this.recentCourses$.next(response.data.recentCourses);
      }
      return this.programsCurrent;
    }),
  );
  programsExisted$ = this.catalogService.getPrograms(false, false).pipe(
    map((response: ApiResponseModel<BundleDashboardListingModel>) => {
      this.programsExisted = response.data.bundles;
      return this.programsExisted;
    }),
  );
  programsCompleted$ = this.catalogService.getPrograms(true).pipe(
    map((response: ApiResponseModel<BundleDashboardListingModel>) => {
      this.programsCompleted = response.data.bundles;
      return response.data.bundles;
    }),
  );

  constructor(
    private catalogService: CatalogService,
    private currentUserStorage: CurrentUserStorage,
    private studentCourseService: StudentCourseService,
  ) {}

  getOnlyEnrolledPrograms(programs: ProgramDashboardModel[]): ProgramDashboardModel[] {
    return programs.filter((model: ProgramDashboardModel) =>
      (this.currentUserStorage.getCurrentUser()?.enrolledBundles || []).includes(model.id),
    );
  }

  getOnlyEnrolledBundles(bundles: BundleModel[]): BundleModel[] {
    return bundles.filter((model: BundleModel) => (this.currentUserStorage.getCurrentUser()?.enrolledBundles || []).includes(model.id));
  }

  loadPrograms(): Observable<ProgramDashboardModel[]> {
    return this.catalogService.getPrograms().pipe(
      map((response: ApiResponseModel<BundleDashboardListingModel>) => {
        this.programsCurrent = response.data.bundles;
        return this.programsCurrent;
      }),
    );
  }

  loadProgramsCompleted(): Observable<ProgramDashboardModel[]> {
    return this.catalogService.getPrograms(true).pipe(
      map((response: ApiResponseModel<BundleDashboardListingModel>) => {
        this.programsCompleted = response.data.bundles;
        return response.data.bundles;
      }),
    );
  }
}
