import { Injectable } from '@angular/core';
import {
  ApiResponseModel,
  BundleModel,
  BundleRecentCoursesModel,
  LearningPathsRecentCoursesModel,
  LearningPathsWrapperModel,
  SpecializationsRecentCoursesModel,
  SpecializationWrapperModel,
} from '@lms/shared/models';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SpecializationService {
  constructor(protected http: ApiService) {}

  getSpecialisations(startButtonClicked: boolean | null = null): Observable<BundleRecentCoursesModel> {
    let params = new HttpParams();

    if (startButtonClicked !== null) {
      params = params.set('startButtonClicked', startButtonClicked ? 1 : 0);
    }

    return this.http.executeGet<ApiResponseModel<SpecializationsRecentCoursesModel>>('specializations', params).pipe(
      map((response: ApiResponseModel<SpecializationsRecentCoursesModel>) => {
        return { bundles: response.data.specializations, recentCourses: response.data.recentCourses } as BundleRecentCoursesModel;
      }),
    );
  }

  getLearningPaths(startButtonClicked: boolean | null = null): Observable<BundleRecentCoursesModel> {
    let params = new HttpParams();

    if (startButtonClicked !== null) {
      params = params.set('startButtonClicked', startButtonClicked ? 1 : 0);
    }

    return this.http.executeGet<ApiResponseModel<LearningPathsRecentCoursesModel>>('learningpaths', params).pipe(
      map((response: ApiResponseModel<LearningPathsRecentCoursesModel>) => {
        return { bundles: response.data.learningPaths, recentCourses: response.data.recentCourses } as BundleRecentCoursesModel;
      }),
    );
  }

  getSpecialisation(url: string): Observable<BundleModel> {
    const params = new HttpParams().set('url', url);
    return this.http
      .executeGet<ApiResponseModel<SpecializationWrapperModel>>('specializations/show', params)
      .pipe(map((response: ApiResponseModel<SpecializationWrapperModel>) => response.data.specialization || ({} as BundleModel)));
  }

  getLearningPath(url: string): Observable<BundleModel> {
    const params = new HttpParams().set('url', url);
    return this.http
      .executeGet<ApiResponseModel<LearningPathsWrapperModel>>('learningpaths/show', params)
      .pipe(map((response: ApiResponseModel<LearningPathsWrapperModel>) => response.data.learningPath || ({} as BundleModel)));
  }
}
