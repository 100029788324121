import { Injectable } from '@angular/core';
import {
  AdminBundleAutocompleteListingModel,
  AdminBundleAutocompleteModel,
  ApiResponseModel,
  BundleCertificationWrapperModel,
  BundleModel,
  BundlesListingModel,
  BundleWrapperModel,
  PricingPlanModel,
  PricingPlanShowModel,
  ProgramSearch,
  ProgramSearchModel,
  SpecializationWrapperModel,
} from '@lms/shared/models';
import { catchError, Observable, of, ReplaySubject, shareReplay } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';

const DEFAULT_LANDING_PROGRAMS_COUNT = 6;

@Injectable({ providedIn: 'root' })
export class BundleService {
  landingPrograms: BundleModel[] = [];
  // #TODO: refactor to remove ReplaySubject store from api service
  specialization$$: ReplaySubject<SpecializationWrapperModel> = new ReplaySubject<SpecializationWrapperModel>(1);
  private bundlesSlugCache: string[] = [];

  constructor(private apiService: ApiService) {}

  loadSpecializationCoursesByUrl(url: string): Observable<boolean> {
    return this.getCertificationsCoursesByUrl(url).pipe(
      map((certification: SpecializationWrapperModel) => {
        this.specialization$$.next(certification);
        return !!certification;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }
  getLearningPathCoursesByUrl(url: string): Observable<SpecializationWrapperModel> {
    const params = new HttpParams().set('url', url);
    return this.apiService.executeGet<ApiResponseModel<any>>('learningpaths/show', params).pipe(
      map(path => {
        path.data.specialization = path.data.learningPath;
        delete path.data.learningPath;
        return path.data;
      }),
    );
  }
  landingCertificationPrograms(): Observable<BundleModel[]> {
    const params = new HttpParams().set('startButtonClicked', 0).set('limit', DEFAULT_LANDING_PROGRAMS_COUNT);

    return this.apiService.executeGet<ApiResponseModel<BundlesListingModel>>('bundles/landing_programs', params).pipe(
      map(res => {
        this.landingPrograms = res.data.bundles;
        return res.data.bundles;
      }),
    );
  }

  getProgramByUrl(url: string, companyId: number | null = null): Observable<BundleWrapperModel> {
    let params = new HttpParams().set('url', url);
    if (companyId) {
      params = params.set('companyId', companyId.toString());
    }
    return this.apiService.executeGet<ApiResponseModel<BundleWrapperModel>>('bundles/programs', params).pipe(pluck('data'));
  }

  getProgramByUuid(uuid: string): Observable<BundleWrapperModel> {
    return this.apiService.executeGet<ApiResponseModel<BundleWrapperModel>>(`bundles/get/${uuid}`).pipe(pluck('data'));
  }

  getProgramByUrlShort(url: string): Observable<BundleWrapperModel> {
    const params = new HttpParams().set('url', url);
    return this.apiService.executeGet<ApiResponseModel<BundleWrapperModel>>('bundles/programs/show_limited', params).pipe(pluck('data'));
  }

  getProgramWithCertificationCoursesByUrl(url: string): Observable<BundleCertificationWrapperModel> {
    const params = new HttpParams().set('url', url);
    return this.apiService
      .executeGet<ApiResponseModel<BundleCertificationWrapperModel>>('bundles/programs_certification', params)
      .pipe(pluck('data'));
  }

  getCertificationsCoursesByUrl(url: string): Observable<SpecializationWrapperModel> {
    const params = new HttpParams().set('url', url);
    return this.apiService.executeGet<ApiResponseModel<SpecializationWrapperModel>>('specializations/show', params).pipe(pluck('data'));
  }

  getProgramPricingPlan(url: string, pricingPlanId: null | string): Observable<PricingPlanModel> {
    let params = new HttpParams().set('bundleUrl', url);
    if (pricingPlanId) {
      params = params.set('pricingPlanId', pricingPlanId + '');
    }
    return this.apiService
      .executeGet<ApiResponseModel<PricingPlanShowModel>>('bundles/pricing_plans', params)
      .pipe(pluck('data', 'pricingPlan'));
  }

  getProgramSearch(companyId?: number): Observable<ProgramSearchModel[]> {
    let params = new HttpParams().set('limit', 5);
    if (companyId) params = params.set('companyId', companyId);
    return this.apiService.executeGet<ApiResponseModel<ProgramSearch>>('bundles/explore_programs', params).pipe(
      map(data => data?.data?.bundles),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  autocompleteListing(): Observable<AdminBundleAutocompleteModel[]> {
    return this.apiService
      .executeGet<ApiResponseModel<AdminBundleAutocompleteListingModel>>('bundles/programs/autocomplete_listing')
      .pipe(pluck('data', 'bundles'));
  }
  autocompleteLearningPathListing(): Observable<AdminBundleAutocompleteModel[]> {
    return this.apiService
      .executeGet<ApiResponseModel<AdminBundleAutocompleteListingModel>>('bundles/learningpaths/autocomplete_listing')
      .pipe(pluck('data', 'learningPaths'));
  }
  bundlesAutocompleteListing(): Observable<AdminBundleAutocompleteModel[]> {
    return this.apiService
      .executeGet<ApiResponseModel<AdminBundleAutocompleteListingModel>>('bundles/bundles/autocomplete_listing')
      .pipe(pluck('data', 'bundles'));
  }

  getBundleSlugs(): Observable<string[]> {
    if (this.bundlesSlugCache.length) {
      return of(this.bundlesSlugCache);
    }
    return this.autocompleteListing().pipe(
      map(bundles => {
        this.bundlesSlugCache = bundles.map(b => b.slug);
        return this.bundlesSlugCache;
      }),
    );
  }
}
